import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import dayjs from "dayjs";
import { Avatar, Modal } from "@sizdevteam1/funjoiner-uikit";
import SizedBox from "src/components/SizedBox";
import useStores from "src/hooks/useStores";
import { IStudentDTO } from "src/services/api/students";
import styles from "../styles.module.scss";
import QRCode from "src/components/QrCode";

import classNames from "classnames";

interface IProps {
  onClose: () => void;
  studentId?: number;
}

const QrCodesModal: React.FC<IProps> = ({ studentId, onClose }) => {
  const { customerStore } = useStores();
  const studentsWithCustomer = customerStore.studentsWithCustomerAsParticipant;
  const [selectedParticipant, setSelectedParticipant] = useState(
    studentsWithCustomer.find((s) => s.id === studentId) ??
      studentsWithCustomer.at(0),
  );

  useEffect(
    () => {
      if (!selectedParticipant) return;
      const index = studentsWithCustomer.indexOf(selectedParticipant);
      scrollByIndex(index);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const scrollByIndex = (index: number) => {
    if (ref.current) {
      ref.current.scrollLeft = 103 * index - 103;
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  if (!selectedParticipant) return null;

  const scrollArrowForward = () => {
    if (ref.current) {
      ref.current.scrollLeft = ref.current?.scrollLeft + 206;
    }
  };

  const scrollArrowBack = () => {
    if (ref.current) {
      ref.current.scrollLeft = ref.current?.scrollLeft - 206;
    }
  };

  return (
    <Modal
      className={styles.modal}
      onClose={onClose}
      closeOnBackDrop={true}
      contentClassName="!p-0 !bg-inherit"
      containerClassName="min-w-[366px] max-w-[414px] w-full !rounded-none !shadow-none select-none"
    >
      <div
        onClick={onClose}
        className="typography-button mb-3 ml-auto flex w-fit cursor-pointer items-center gap-[6px] pt-1 text-on-main-color"
      >
        Close
        <i className="icon close-icon mr-[2px] bg-on-main-color" />
      </div>
      <div className={classNames(styles.header)}>
        <div className="typography-main pt-8 text-center text-gray-text-color">
          Code for Check In/Out
        </div>
        <div className="flex justify-center pt-3">
          <QRCode data={selectedParticipant.qr} />
        </div>
        <div className={cn(styles.circle, "left-[-22px]")} />
        <div className={styles.avatarWrapper}>
          <Avatar person={selectedParticipant} avatarSize={"86px"} />
        </div>
        <div className={styles.circle} />
      </div>
      <div
        className={classNames(styles.body, "rounded-b-[20px] bg-card-color")}
      >
        <SizedBox height={33} />
        <div
          className={cn(
            styles.name,
            "text-h2 max-w-[366px] overflow-hidden text-ellipsis whitespace-nowrap pb-[2px]",
          )}
        >
          {selectedParticipant.first_name} {selectedParticipant.last_name}
        </div>
        <div className={cn(styles.age, "text-main")}>
          {dayjs().diff(selectedParticipant.birth_date, "years")} yrs
        </div>
        {/* <button
          onClick={() => {}} 
          className={`typography-main_sb mt-5 flex w-full cursor-pointer items-center 
        justify-center gap-[10px] rounded-[6px] border-none bg-overlay-color py-1 text-on-main-color`}
        >
          <i className="icon wallet-icon" />
          Add to Apple Wallet
        </button> */}
      </div>
      <div
        className={classNames(
          "my-8 flex items-center gap-3",
          studentsWithCustomer.length > 3
            ? "justify-between"
            : "justify-center",
        )}
      >
        {studentsWithCustomer.length > 3 && (
          <i
            onClick={scrollArrowBack}
            className={classNames(
              "icon chevron-left-icon cursor-pointer bg-on-main-color",
            )}
          />
        )}

        <div
          ref={ref}
          className={classNames(
            "flex w-[294px] items-center gap-[15px] overflow-x-scroll overscroll-x-contain scroll-smooth",
            styles.hideScroll,
            studentsWithCustomer.length <= 3 && "justify-center",
          )}
        >
          {studentsWithCustomer.map((s, index) => (
            <Student
              key={s.id}
              student={s}
              onSelect={() => {
                setSelectedParticipant(s);
                scrollByIndex(index);
              }}
              isSelected={selectedParticipant.id === s.id}
            />
          ))}
        </div>
        {studentsWithCustomer.length > 3 && (
          <i
            onClick={scrollArrowForward}
            className="icon chevron-right-icon cursor-pointer bg-on-main-color"
          />
        )}
      </div>
    </Modal>
  );
};

const Student = ({
  student,
  onSelect,
  isSelected,
}: {
  student: IStudentDTO;
  onSelect: () => void;
  isSelected: boolean;
}) => {
  const { customerStore } = useStores();
  return (
    <div className="flex cursor-pointer">
      <div onClick={onSelect} className="flex flex-col items-center gap-[6px]">
        <div
          className={classNames(
            "box-border flex h-[56px] w-[56px] items-center justify-center rounded-full",
            isSelected &&
              "border-[2px] border-solid border-on-main-color bg-overlay-color",
          )}
        >
          <Avatar
            className="!bg-on-main-color"
            person={student}
            avatarSize="48px"
          />
        </div>
        <div
          className={classNames(
            "typography-label overflow-hidden text-ellipsis whitespace-nowrap text-center text-on-main-color",
            student.type === "CUSTOMER_AS_PARTICIPANT"
              ? "w-[58px]"
              : "w-[88px]",
          )}
        >
          {student.type === "CUSTOMER_AS_PARTICIPANT"
            ? "(You)"
            : student.first_name}
        </div>
      </div>
      {student.type === "CUSTOMER_AS_PARTICIPANT" &&
        customerStore.studentsWithCustomerAsParticipant.length > 1 && (
          <div className="ml-6 mr-[5px] w-[1px] rounded-lg bg-on-main-color"></div>
        )}
    </div>
  );
};

export default QrCodesModal;
