import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { NewAppVersionSentinel, Splash } from "@sizdevteam1/funjoiner-uikit";
import useStores from "./hooks/useStores";
import { Observer } from "mobx-react-lite";
import AppHeader from "./pages/AppHeader";
import Footer from "./components/Footer";
import { ROUTES } from "./stores/RouterStore";
import PublicRouteContent from "./components/PublicRouteContent";
import PrivateRouteContent from "./components/PrivateRouteContent";
import CommunicationPreferencesModal from "./components/CommunicationPreferencesModal";
import { afterScheduleQuestionSetsRoute } from "./pages/AfterScheduleQuestionSetsPage/afterScheduleQuestionSetsRoute";
import { studentQuestionSetRoute } from "./pages/SmartFormsPage/StudentQuestionSetPage/studentQuestionSetRoute";
import { cancelCreditRoute } from "./pages/SelfRechedulePage/cancelCreditRoute";
import { rescheduleRoute } from "./pages/SelfRechedulePage/rescheduleRoute";
import { applicationsAndWaitlistsPageRoute } from "./pages/ApplicationsAndWaitlistsPage/applicationsAndWaitlistsRoute";
import { applicationPageRoute } from "./pages/ApplicationPage/applicationPageRoute";
import { NewAppVersionMessage } from "./components/NewAppVersionMessage";
import { ErrorBoundary } from "@sentry/react";
import { ErrorBoundaryPage } from "./components/ErrorBoundaryPage";
import { ImmediateRedirect } from "./components/ImmediateRedirect";
import CompanyLockedPage from "./pages/CompanyLockedPage/CompanyLockedPage";
import { RestrictedPage } from "./pages/RestrictedPage";

const DelayedLazy: typeof React.lazy = (load) => {
  return React.lazy(async () => {
    const result = load();
    await new Promise((resolve) => setTimeout(resolve, 500));
    return result;
  });
};

const ApplicationPage = DelayedLazy(
  () => import("./pages/ApplicationPage/ApplicationPage"),
);

const ApplicationsAndWaitlistsPage = DelayedLazy(
  () =>
    import("./pages/ApplicationsAndWaitlistsPage/ApplicationsAndWaitlistsPage"),
);

const SelfReschedulePage = DelayedLazy(
  () => import("./pages/SelfRechedulePage/SelfReschedulePage"),
);
const CancelCreditPage = DelayedLazy(
  () => import("./pages/SelfRechedulePage/CancelCreditPage"),
);
const StudentQuestionSetPage = DelayedLazy(
  () =>
    import(
      "./pages/SmartFormsPage/StudentQuestionSetPage/StudentQuestionSetPage"
    ),
);
const AfterScheduleQuestionSetsPage = DelayedLazy(
  () =>
    import(
      "./pages/AfterScheduleQuestionSetsPage/AfterScheduleQuestionSetsPage"
    ),
);
const ScheduleSharingPage = DelayedLazy(
  () => import("./pages/SuccessPages/ScheduleSharingPage"),
);
const InvoiceSentSuccessPage = DelayedLazy(
  () => import("./pages/SuccessPages/InvoiceSentSuccessPage"),
);
const PersonalInfoPage = DelayedLazy(
  () => import("./pages/ProfilePage/PersonalInfoPage/PersonalInfoPage"),
);
const ProfilePage = DelayedLazy(
  () => import("./pages/ProfilePage/ProfilePage/ProfilePage"),
);
const OrderPaymentPlanPage = DelayedLazy(
  () => import("./pages/OrderPaymentPlanPage/OrderPaymentPlanPage"),
);
const WelcomeScreenPage = DelayedLazy(
  () => import("./pages/WelcomeScreenPage/WelcomeScreenPage"),
);
const CreditsAndPackagesPage = DelayedLazy(
  () => import("./pages/CreditsAndPackagesPage/CreditsAndPackagesPage"),
);
const ExploreOfferingsPage = DelayedLazy(
  () => import("./pages/ExploreOfferingsPage/ExploreOfferingsPage"),
);
const PreselectFunboxByIdOrUrlPartPage = DelayedLazy(
  () =>
    import(
      "./pages/PreselectFunboxByIdOrUrlPartPage/PreselectFunboxByIdOrUrlPartPage"
    ),
);
const ParticipantsPage = DelayedLazy(
  () => import("./pages/ParticipantsPage/ParticipantsPage"),
);
const ContactsPage = DelayedLazy(
  () => import("./pages/ContactsPage/ContactsPage"),
);

const SelectFunboxPage = DelayedLazy(() => import("./pages/SelectFunboxPage"));
const SignInPage = DelayedLazy(() => import("./pages/SignInPage"));
const ThankYouPage = DelayedLazy(
  () => import("./pages/SuccessPages/ScheduleSuccessPage"),
);
const DashboardPage = DelayedLazy(() => import("./pages/DashboardPage"));
const AvailabilityPage = DelayedLazy(() => import("./pages/AvailabilityPage"));
const HistoryPage = DelayedLazy(() => import("./pages/HistoryPage"));
const CardsPage = DelayedLazy(() => import("./pages/CardsPage"));
const DocumentsPage = DelayedLazy(() => import("./pages/DocumentsPage"));
const PaymentSuccessPage = DelayedLazy(
  () => import("./pages/SuccessPages/PaymentSuccessPage"),
);
const SchedulePage = DelayedLazy(() => import("./pages/Schedule/SchedulePage"));
const HelpPage = DelayedLazy(() => import("./pages/HelpPage"));
const DirectionsPage = DelayedLazy(() => import("./pages/DirectionsPage"));
const ScheduleAndPaySuccessPage = DelayedLazy(
  () => import("./pages/SuccessPages/ScheduleAndPaySuccessPage"),
);

const UpgradeCreditPage = DelayedLazy(
  () => import("./pages/UpgradeCredit/UpgradeCreditPage"),
);
const PromocodesPage = DelayedLazy(
  () => import("./pages/PromocodesPage/PromocodesPage"),
);
const CustomDocumentPage = DelayedLazy(
  () => import("./pages/DocumentsPage/CustomDocumentInDocumentsPage"),
);
const ParticipantCreateOrEditPage = DelayedLazy(
  () => import("./pages/ParticipantCreateOrEditPage"),
);
const SmartFormsPage = DelayedLazy(
  () => import("./pages/SmartFormsPage/SmartFormsPage"),
);
const ApplicationProcessPage = DelayedLazy(
  () => import("./pages/ApplicationProcessPage/ApplicationProcessPage"),
);

const App: React.FC = () => {
  const rootStore = useStores();
  const { paymentStore, funboxStore, commonStore, customerStore, authStore } =
    rootStore;

  return (
    <ErrorBoundary
      fallback={<ErrorBoundaryPage className={"box-border pt-[160px]"} />}
    >
      <div className="App">
        <Observer>
          {() =>
            rootStore.showAppLoading ? (
              <Splash pathToAnimation={"/splash.json"} />
            ) : commonStore.isCompanyLocked ? (
              <CompanyLockedPage />
            ) : (
              <Suspense fallback={<Splash pathToAnimation={"/splash.json"} />}>
                <Elements stripe={paymentStore.stripePromise}>
                  <CommunicationPreferencesModal />
                  {customerStore.initialized &&
                  authStore.initialized &&
                  customerStore.customer.is_restricted ? (
                    <RestrictedPage />
                  ) : (
                    <main>
                      <AppHeader />
                      <Routes>
                        {[
                          ROUTES.SELECT_FUNBOX_PAGE_BY_URL_PART_ACTION,
                          ROUTES.SELECT_FUNBOX_PAGE_BY_ID_ACTION,
                        ].map((path, index) => (
                          <Route
                            key={index}
                            path={path}
                            element={
                              <PublicRouteContent title={"Lineup"}>
                                <PreselectFunboxByIdOrUrlPartPage />
                              </PublicRouteContent>
                            }
                          />
                        ))}

                        <Route
                          path={ROUTES.SELECT_FUNBOX_PAGE}
                          element={
                            <PublicRouteContent title={"Lineup"}>
                              <SelectFunboxPage />
                            </PublicRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.SIGN_IN}
                          element={
                            <PublicRouteContent title={"Sign In"}>
                              <SignInPage />
                            </PublicRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.ADD_PARTICIPANT}
                          element={
                            <PublicRouteContent title={"Add Participant Page"}>
                              <ParticipantCreateOrEditPage />
                            </PublicRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.EDIT_PARTICIPANT}
                          element={
                            <PublicRouteContent title={"Edit Participant Page"}>
                              <ParticipantCreateOrEditPage />
                            </PublicRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.HELP}
                          element={
                            <PublicRouteContent title={"Get Help"}>
                              <HelpPage />
                            </PublicRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.DASHBOARD}
                          element={
                            <PrivateRouteContent title={"My Dashboard"}>
                              <DashboardPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.PROFILE}
                          element={
                            <PrivateRouteContent title={"Profile"}>
                              <ProfilePage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.CONTACTS}
                          element={
                            <PrivateRouteContent title={"Contacts Page"}>
                              <ContactsPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.HISTORY}
                          element={
                            <PrivateRouteContent title={"Transaction History"}>
                              <HistoryPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.CARDS}
                          element={
                            <PrivateRouteContent title={"Cards"}>
                              <CardsPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.DOCUMENTS}
                          element={
                            <PrivateRouteContent title={"Documents"}>
                              <DocumentsPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.PARTICIPANTS}
                          element={
                            <PrivateRouteContent title={"Participants"}>
                              <ParticipantsPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.PERSONAL_INFO}
                          element={
                            <PrivateRouteContent title={"Personal Information"}>
                              <PersonalInfoPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.PROMOCODES}
                          element={
                            <PrivateRouteContent title={"Promo Codes"}>
                              <PromocodesPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.CUSTOM_DOCUMENT_IN_DOCUMENTS}
                          element={
                            <PrivateRouteContent title={"Custom Document"}>
                              <CustomDocumentPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={studentQuestionSetRoute.path}
                          element={
                            <PrivateRouteContent title={"Personal Information"}>
                              <StudentQuestionSetPage
                                description={
                                  "The following questions are required. Please answer them before attendance."
                                }
                              />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.SMART_FORMS}
                          element={
                            <PrivateRouteContent title={"Personal Information"}>
                              <SmartFormsPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={afterScheduleQuestionSetsRoute.path}
                          element={
                            <PrivateRouteContent title={"Personal Information"}>
                              <AfterScheduleQuestionSetsPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={applicationPageRoute.path}
                          element={
                            <PrivateRouteContent title={"Application"}>
                              <ApplicationPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={applicationsAndWaitlistsPageRoute.path}
                          element={
                            <PrivateRouteContent
                              title={"Applications/Waitlists"}
                            >
                              <ApplicationsAndWaitlistsPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={`${ROUTES.SCHEDULE}/*`}
                          element={
                            <PrivateRouteContent title={"Schedule Dates"}>
                              <SchedulePage />
                            </PrivateRouteContent>
                          }
                        />

                        <Route
                          path={ROUTES.PAYMENT_SUCCESS}
                          element={
                            <PrivateRouteContent title={"Payment Successful!"}>
                              <PaymentSuccessPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={rescheduleRoute.path}
                          element={
                            <PrivateRouteContent title={"Self Reschedule"}>
                              <SelfReschedulePage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={cancelCreditRoute.path}
                          element={
                            <PrivateRouteContent title={"Cancel Reservation"}>
                              <CancelCreditPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.UPGRADE_CREDIT + "/*"}
                          element={
                            <PrivateRouteContent title={"Self Upgrade"}>
                              <UpgradeCreditPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.PAYMENT_PLAN}
                          element={
                            <PrivateRouteContent title={"Payment Plan"}>
                              <OrderPaymentPlanPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.WELCOME_SCREEN}
                          element={
                            <PublicRouteContent title="Welcome">
                              <WelcomeScreenPage />
                            </PublicRouteContent>
                          }
                        />
                        {funboxStore.isBuyCreditsAndSaveEnabled && (
                          <Route
                            path={ROUTES.FLEXIBLE_PAYMENTS + "/*"}
                            element={
                              <PublicRouteContent title="Credits & Packages">
                                <CreditsAndPackagesPage />
                              </PublicRouteContent>
                            }
                          />
                        )}
                        <Route
                          path={ROUTES.EXPLORE_OFFERINGS}
                          element={
                            <PublicRouteContent title="Explore Offerings">
                              <ExploreOfferingsPage />
                            </PublicRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.AVAILABILITY}
                          element={
                            <PublicRouteContent title={"Check Schedule"}>
                              <AvailabilityPage />
                            </PublicRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.DIRECTIONS_DETAILS}
                          element={
                            <PrivateRouteContent title="Directions and details">
                              <DirectionsPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.SCHEDULE_SHARING}
                          element={
                            <PrivateRouteContent title="Schedule Sharing">
                              <ScheduleSharingPage />
                            </PrivateRouteContent>
                          }
                        />

                        <Route
                          path={ROUTES.THANKYOU}
                          element={
                            <PrivateRouteContent title="Thank You!">
                              <ThankYouPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.SCHEDULE_AND_PAY_SUCCESS}
                          element={
                            <PrivateRouteContent title="Thank You!">
                              <ScheduleAndPaySuccessPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.INVOICE_SENT_SUCCESS}
                          element={
                            <PrivateRouteContent title="Invoice Sent!">
                              <InvoiceSentSuccessPage />
                            </PrivateRouteContent>
                          }
                        />
                        <Route
                          path={ROUTES.LOCATIONS}
                          element={
                            <ImmediateRedirect
                              to={ROUTES.SELECT_FUNBOX_PAGE}
                              replace={true}
                            />
                          }
                        />
                        <Route
                          path={ROUTES.ROOT}
                          element={
                            <ImmediateRedirect
                              to={ROUTES.WELCOME_SCREEN}
                              replace={true}
                            />
                          }
                        />
                        <Route
                          path={"*"}
                          element={
                            <ImmediateRedirect
                              to={ROUTES.ROOT}
                              replace={true}
                            />
                          }
                        />
                      </Routes>
                      <Footer />
                    </main>
                  )}
                </Elements>
              </Suspense>
            )
          }
        </Observer>
        <NewAppVersionSentinel
          currentVersion={window._env_.COMMIT_TIMESTAMP}
          versionUrl={`${window.location.protocol}//${window.location.host}/version.txt`}
          renderChild={(forceRefresh) => {
            return <NewAppVersionMessage forceRefresh={forceRefresh} />;
          }}
          onVersionFetchError={(e) => {
            console.error("Error fetching version", e);
          }}
        />
      </div>
    </ErrorBoundary>
  );
};

export default App;
