import { Button, Image } from "@sizdevteam1/funjoiner-uikit";
import FunJoinerLogo from "../assets/imgs/fj_logo_sm.svg";
import React from "react";
import useStores from "../hooks/useStores";
import HelpPage from "./HelpPage";

interface RestrictedPageProps {
  className?: string;
}

export const RestrictedPage = (props: RestrictedPageProps) => {
  const { commonStore, authStore } = useStores();
  return (
    <div className="mx-auto w-[366px]">
      <div className="mb-5 flex h-[80px] items-center justify-between bg-[#f6f7f8]">
        <Image
          className="!h-[52px] !max-h-[52px] w-fit !min-w-[52px] !max-w-[80px]"
          src={commonStore.companyProfile?.logo || FunJoinerLogo}
          alt={commonStore.companyProfile?.name}
        />
        <Button
          className="w-fit"
          onClick={authStore.signOut}
          autoLoading
          kind="text"
        >
          <i className="icon log-out-icon icon-lg" />
          Log Out
        </Button>
      </div>
      <HelpPage />
    </div>
  );
};
