import React from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import PageRoot from "src/components/PageRoot";
import SizedBox from "src/components/SizedBox";
import AttentionMessage from "src/components/AttentionMessage";
import DirectionsBlock from "src/components/DirectionsBlock";
import styles from "./HelpPage.module.scss";
import PageHeader from "../../components/PageHeader";
import formatPhone from "../../util/formatPhone";
import { Button } from "@sizdevteam1/funjoiner-uikit";

interface IProps {}

const HelpPage: React.FC<IProps> = observer(() => {
  const {
    commonStore: { companyProfile, publicSettings },
    customerStore,
    authStore,
  } = useStores();

  return (
    <PageRoot className={styles.rules}>
      {customerStore.initialized &&
        authStore.initialized &&
        customerStore.customer.is_restricted && (
          <div className="mb-6 box-border grid w-[366px] grid-cols-[58px_1fr] rounded-[10px] bg-on-main-color shadow-big">
            <div className="flex h-full items-center justify-center rounded-l-[10px] bg-delete-color">
              <i className="icon big-cross-icon h-9 w-9 bg-on-main-color" />
            </div>
            <div className="py-4 pl-4 pr-[34px]">
              <div className="typography-h3 text-header-color">
                Account Login Restriction
              </div>
              <div className="typography-small__t mt-1 flex flex-col gap-1 text-gray-text-color">
                <span>
                  {companyProfile.name} has{" "}
                  <span className="text-icon-red-color">
                    temporarily restricted your account
                  </span>{" "}
                  from login access, scheduling and transacting.
                </span>
                <span>
                  In some cases, this may happen automatically
                  <span className="text-text-color">
                    {" "}
                    due to unpaid invoices{" "}
                  </span>
                  older than one (1) year, based on the preferred account
                  settings.
                </span>
                <span>
                  If you believe this was done in error, please contact{" "}
                  {companyProfile.name} support.
                </span>
              </div>
            </div>
          </div>
        )}
      <PageHeader>Support</PageHeader>

      <p className={cn("text-main text-gray", styles.info)}>
        If you need any information, please feel free to visit our{" "}
        <a href={companyProfile?.website} target={"_blank"} rel="noreferrer">
          website
        </a>
        , or you can reach us through the contact info below.
      </p>
      <div className={styles.contacts}>
        {companyProfile?.phone && (
          <div className={styles.contact}>
            <i className={"icon mobile-icon"} />
            <div className={"typography-main"}>
              <a href={`tel:${companyProfile.phone}`}>
                {formatPhone(companyProfile.phone)}
              </a>
            </div>
          </div>
        )}
        {companyProfile?.email && (
          <div className={styles.contact}>
            <i className={"icon emails-icon"} />
            <div className={"typography-main"}>
              <a href={`mailto:${companyProfile.email}`}>
                {companyProfile.email}
              </a>
            </div>
          </div>
        )}
      </div>
      <SizedBox height={24} />
      <DirectionsBlock />
      <AttentionMessage
        text={publicSettings.customer_hub_settings.attention_message.text}
        enabled={publicSettings.customer_hub_settings.attention_message.enabled}
        color="#F55050"
      />
    </PageRoot>
  );
});

export default HelpPage;
