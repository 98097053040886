import { action, computed, makeObservable, observable } from "mobx";
import RootStore from "./RootStore";
import api, { IStudentDTO, TDocumentDTO } from "../services/api";
import notificator from "../services/systemNotifications/notificationCenterService";
import { repeatWhile } from "../util/repeatUntil";
import { computedFn } from "mobx-utils";
import { isSignedByCustomer } from "src/util/isSignedByCustomer";

export default class DocumentsStore {
  constructor(public rootStore: RootStore) {
    makeObservable(this);
  }

  @observable
  actualDocuments: TDocumentDTO[] = []; // EXCLUDED EXPIRED DOCS!!!

  @observable
  initialized = false;

  @action
  loadDocuments = async () => {
    this.actualDocuments = await api.documents.get();
    this.initialized = true;
  };

  @observable
  _polledDocumentIds = observable.set<string>();

  isWaitingForDocumentUpdate = computedFn((documentId: string) => {
    return this._polledDocumentIds.has(documentId);
  });

  waitUntilDocumentIsSigned = async (documentId: string): Promise<void> => {
    if (this.isSigned(documentId)) return;
    this._polledDocumentIds.add(documentId);

    try {
      await repeatWhile(
        () => this.refreshDocument(documentId),
        () => !this.isSigned(documentId),
      );
    } finally {
      this._polledDocumentIds.delete(documentId);
    }
  };

  isSigned = computedFn((documentId: string) => {
    return (
      this.actualDocuments.find((document) => document.id === documentId)
        ?.signed === true
    );
  });

  refreshDocument = async (documentId: string) => {
    const document = await api.documents.getById(documentId);
    const index = this.actualDocuments.findIndex(
      (document) => document.id === documentId,
    );

    if (index === -1) {
      this.actualDocuments.push(document);
    } else {
      this.actualDocuments[index] = document;
    }
  };

  @action
  getSigningLink = async (documentId: string) => {
    try {
      return await api.documents.getSigningLink(documentId);
    } catch (e) {
      notificator.error("Failed to get signing link");
      throw e;
    }
  };

  @computed
  get studentsWithDocuments(): IStudentDTO[] {
    return this.rootStore.customerStore.studentsWithCustomerAsParticipant.filter(
      (e) => this.getStudentDocuments(e.id).length > 0,
    );
  }

  getStudentDocuments = (studentId: number | undefined) => {
    return this.actualDocuments.filter((e) => e.student_id === studentId);
  };

  getSignedDocumentsByStudentId = computedFn((studentId: number) => {
    return this.getStudentDocuments(studentId).filter((d) =>
      isSignedByCustomer(d),
    );
  });

  getUnsignedDocumentsByStudentId = computedFn((studentId: number) => {
    return this.getStudentDocuments(studentId).filter(
      (d) => !isSignedByCustomer(d),
    );
  });

  completenessForStudentByStudentId = computedFn(
    (studentId: number): number => {
      const studentDocumentsLength = this.getStudentDocuments(studentId).length;
      let ratio: number;
      if (studentDocumentsLength === 0) {
        ratio = 1;
      } else {
        const signedLength =
          this.getSignedDocumentsByStudentId(studentId).length;
        ratio = signedLength / studentDocumentsLength;
      }
      return Math.floor(ratio * 100);
    },
  );

  @computed
  get averageCompleteness(): number | undefined {
    if (this.studentsWithDocuments.length === 0) return undefined;
    return Math.floor(
      this.studentsWithDocuments.reduce(
        (avg, e) => avg + this.completenessForStudentByStudentId(e.id),
        0,
      ) / this.studentsWithDocuments.length,
    );
  }
}
