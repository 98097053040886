import React from "react";
import PieChart from "../pages/DashboardPage/components/PieChart/PieChart";
import cn from "classnames";

export const PieChartCompletenessCard = ({
  name,
  percentage,
  count,
  renderNavigateButton,
  className,
}: {
  name: string;
  percentage: number;
  count: number;
  renderNavigateButton?: () => React.ReactNode;

  className?: string;
}) => {
  const isCompleted = percentage === 100;
  return (
    <div
      className={cn(
        "flex max-w-[175px] justify-between rounded-lg bg-on-main-color px-[10px] py-3 shadow-big",
        renderNavigateButton == null && "items-center",
        className,
      )}
    >
      <PieChart percentage={percentage} />
      <div className="flex h-full flex-col justify-between">
        <div className="typography-label flex items-center justify-end gap-[2px] text-gray-text-color">
          <div>{name}</div>
          {!isCompleted && (
            <>
              <div>•</div>
              <span className="text-text-color">{count}</span>
            </>
          )}
        </div>
        <div className="flex gap-[2px]">
          {isCompleted ? (
            <span className="typography-label pb-[4.5px] text-icon-dark-green-color">
              Completed!
            </span>
          ) : (
            renderNavigateButton?.()
          )}
        </div>
      </div>
    </div>
  );
};
