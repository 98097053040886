import apiService from "./apiService";
import { ICustomerDTO, IStorageFileDTO } from ".";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";

export const ALL_PERSONAL_PRONOUNS = [
  "none",
  "he",
  "she",
  "they",
  "non-binary",
] as const;
export type TPersonalPronoun = (typeof ALL_PERSONAL_PRONOUNS)[number];
export type TStudentType = "CUSTOMER_AS_PARTICIPANT" | "REGULAR";
export type Gender = "MALE" | "FEMALE";
export interface IStudentDTO {
  age: number;
  allergies: string;
  ascii_full_name: string;
  attachments: IStorageFileDTO[];
  birth_date: ISOString;
  customer: ICustomerDTO;
  customer_notes: string;
  employee_notes: string;
  first_name: string;
  full_name: string;
  gender: Gender | null;
  grade: number | null;
  id: number;
  last_name: string;
  medical_considerations: string;
  name: string;
  personal_pronoun: TPersonalPronoun;
  photo?: string;
  photo_file?: IStorageFileDTO;
  photo_key?: string;
  qr: string;
  thumbnail?: string;
  thumbnail_file?: IStorageFileDTO;
  thumbnail_key?: string;
  type: TStudentType;
}

export interface ICreateStudentDTO {
  allergies?: string;
  birth_date: ISOString;
  customer_notes?: string;
  first_name: string;
  gender?: Gender;
  grade?: number | null;
  last_name: string;
  medical_considerations?: string;
  personal_pronoun?: TPersonalPronoun;
  photo_key?: string | null;
}

export interface ICreateCustomerAsParticipantDTO {
  first_name: string | undefined;
  last_name: string | undefined;
  phone_number?: string | null;
  email?: string | null;
  address: string | undefined;

  birth_date: ISOString;
  allergies?: string;
  medical_considerations?: string;
  customer_notes?: string;
  personal_pronoun?: TPersonalPronoun;
  gender?: Gender;
  grade?: number | null;
  photo_key?: string | null;
}

export interface IUpdateStudentDTO extends Partial<ICreateStudentDTO> {}

export const create = (data: ICreateStudentDTO): Promise<IStudentDTO> =>
  apiService.post("/customer_api/v2/students/", { data });

export const update = (
  id: number,
  data: IUpdateStudentDTO,
): Promise<IStudentDTO> =>
  apiService.put(`/customer_api/v2/students/${id}`, { data });

export const remove = (id: string | number) =>
  apiService.delete(`/customer_api/v2/students/${id}`);

export const createCustomerAsParticipant = (
  data: ICreateCustomerAsParticipantDTO,
): Promise<IStudentDTO> =>
  apiService.put("/customer_api/v2/students/customer_as_participant", {
    data,
  });
