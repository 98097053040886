import { UNSAFE_RouteContext as RouteContext } from "react-router";
import { useContext, useMemo } from "react";
import { Routes, RoutesProps } from "react-router-dom";

export const RoutesWithAbsolutePaths = (props: RoutesProps) => {
  const ctx = useContext(RouteContext);

  const value = useMemo(
    () => ({
      ...ctx,
      matches: [],
    }),
    [ctx]
  );

  return (
    <RouteContext.Provider value={value}>
      <Routes {...props} />
    </RouteContext.Provider>
  );
};
