import apiService from "./apiService";
import { IStorageFileDTO } from "./files";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";

export type TDocumentStatus = "UNSIGNED" | "AWAITING_APPROVAL" | "SIGNED";

interface DocumentBaseDTO {
  id: string;
  signer_id: number;
  student_id: number;
  template_id: string;

  name: string;
  status: TDocumentStatus;
  created_at: ISOString;

  signed_at?: ISOString;
  submitted_at: ISOString | null;
  expiration_date?: ISOString;
  attachments: IStorageFileDTO[];

  signed: boolean;
  expired: boolean;
  active: boolean;
  download_url?: string;

  upload_requires_verification: boolean;
  resubmission_reason: string | null;
}

export interface ICustomDocumentDTO extends DocumentBaseDTO {
  type: "custom_document";
  description: string;
  customer_can_upload_to_sign: boolean;
  template_attachments: IStorageFileDTO[];
}

export interface ISignnowDocumentDTO extends DocumentBaseDTO {
  type: "signnow_document";
  signed_document_type?: "PHOTO" | "EDOC";
}

export type TDocumentDTO = ICustomDocumentDTO | ISignnowDocumentDTO;

export const get = (): Promise<TDocumentDTO[]> =>
  apiService.get("/customer_api/v2/documents/");

export const getById = (documentId: string): Promise<TDocumentDTO> =>
  apiService.get(`/customer_api/v2/documents/${documentId}`);

export const getSigningLink = (document_id: string): Promise<string> =>
  apiService.get(`/customer_api/v2/documents/${document_id}/signing_link`);

export const attachFiles = (
  documentId: string,
  fileKeys: string[],
): Promise<TDocumentDTO> =>
  apiService.post(`/customer_api/v2/documents/${documentId}/attach_files`, {
    data: { file_keys: fileKeys },
  });
