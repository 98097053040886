import * as Sentry from "@sentry/react";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { computedFn, fromPromise, IPromiseBasedObservable } from "mobx-utils";
import { IPromocodeDTO } from "src/services/api/profile";
import notificator from "src/services/systemNotifications/notificationCenterService";
import api, {
  AvailableDaycampSessionDTO,
  ICreateStudentDTO,
  ICreditDTO,
  ICustomerWithStudentsDTO,
  IUpdateCustomerDTO,
  TAttendanceDTO,
} from "../services/api";
import {
  ICreateCustomerAsParticipantDTO,
  IStudentDTO,
  IUpdateStudentDTO,
} from "../services/api/students";
import RootStore from "./RootStore";
import { IPagination } from "../services/api/common";
import { IWaitlistRecordDTO } from "../services/api/waitlists";
import { SmartFormDTO } from "src/services/api/smartForms";

import {
  ApplicationDTO,
  TAvailableProgram,
} from "src/services/api/availability";
import exists from "@sizdevteam1/funjoiner-uikit/util/exists";
import { SmartFormUtils } from "../util/questionSetUtils";

export default class CustomerStore {
  constructor(public rootStore: RootStore) {
    makeObservable(this);
  }

  @observable
  _customer: ICustomerWithStudentsDTO | null = null;

  @computed.struct
  get customer(): ICustomerWithStudentsDTO {
    if (this._customer == null) throw new Error("Customer is Null");
    return this._customer;
  }

  @computed
  get isCustomerSmsOptedIn() {
    return (
      this._customer != null &&
      this._customer.phone_number != null &&
      Boolean(this._customer.is_phone_number_communication_opted_in)
    );
  }

  @computed
  get customerNeedsToSetCommunicationPreferences() {
    return (
      this._customer != null &&
      this._customer.phone_number != null &&
      this._customer.is_phone_number_communication_opted_in == null
    );
  }
  @action.bound
  async updateCommunicationPreferences(
    is_phone_number_communication_opted_in: boolean,
  ) {
    try {
      const customer = await api.profile.setCommunicationPreferences({
        is_phone_number_communication_opted_in,
      });
      runInAction(() => {
        if (this._customer != null) {
          this._customer.is_phone_number_communication_opted_in =
            customer.is_phone_number_communication_opted_in;
        }
      });
    } catch (e) {
      notificator.error(e);
      throw e;
    }
  }

  @computed
  get hasFullCustomerInfo() {
    return Boolean(
      this._customer?.first_name &&
        this._customer.last_name &&
        this._customer.email &&
        (this.rootStore.commonStore.publicSettings.customer_hub_settings
          .baseline_questions.for_customers.address.status !== "required" ||
          this._customer.address),
    );
  }
  @computed
  get initialized() {
    return this._customer != null;
  }

  @action.bound
  openParticipantModal(value: number | "add") {
    this.rootStore.routerStore.setSearchParam("participant", value, true);
  }
  @action.bound
  closeParticipantModal() {
    this.rootStore.routerStore.setSearchParam("participant", undefined, true);
  }

  @computed
  get isShowingParticipantModal() {
    return !!this.rootStore.routerStore.searchParams.participant;
  }

  @observable
  credits: ICreditDTO[] = [];

  @observable
  smartForms: SmartFormDTO[] = [];

  @computed
  get incompleteRequiredSmartForms() {
    return SmartFormUtils.filterIncompleteRequired(this.smartForms);
  }

  @computed
  get incompleteOptionalSmartForms() {
    return SmartFormUtils.filterIncompleteOptional(this.smartForms);
  }

  @computed
  get completeSmartForms() {
    return SmartFormUtils.filterComplete(this.smartForms);
  }

  @computed get unansweredRequiredToFillSmartFormsCount() {
    return (
      this.incompleteRequiredSmartForms.length +
      this.incompleteOptionalSmartForms.filter(
        (f) => f.resubmission_reason != null,
      ).length
    );
  }

  @computed get totalSmartFormsToFillCount() {
    const completedRequiredSmartFormsCount = this.completeSmartForms.filter(
      (form) => form.is_required,
    ).length;

    return (
      completedRequiredSmartFormsCount +
      this.unansweredRequiredToFillSmartFormsCount
    );
  }

  @observable
  private scheduleForMonthPromise?: IPromiseBasedObservable<
    IPagination<TAttendanceDTO>
  >;

  @observable
  promocodes: IPromocodeDTO[] = [];

  @computed
  get studentsWithCustomerAsParticipant(): IStudentDTO[] {
    return this.customer.students.map((s) => ({
      ...s,
      customer: this.customer,
    }));
  }

  @computed get studentsWithoutCustomerAsParticipant(): IStudentDTO[] {
    return this.studentsWithCustomerAsParticipant.filter(
      (s) => s.type === "REGULAR",
    );
  }

  @computed get customerAsParticipant() {
    return this.studentsWithCustomerAsParticipant.find(
      (s) => s.type === "CUSTOMER_AS_PARTICIPANT",
    );
  }

  @computed
  get studentsWithQuestions(): IStudentDTO[] {
    return Array.from(new Set(this.smartForms.map((sf) => sf.student_id)))
      .map((s_id) =>
        this.studentsWithCustomerAsParticipant.find(
          (studentId) => studentId.id === s_id,
        ),
      )
      .filter(exists)
      .sort((a, b) => {
        if (a.type === "CUSTOMER_AS_PARTICIPANT" && b.type === "REGULAR")
          return -1;
        else return 1;
      });
  }

  isBooked = computedFn(
    (studentId: number, session: AvailableDaycampSessionDTO) => {
      const promise = this.scheduleForMonthPromise;
      return (
        promise != null &&
        promise.state === "fulfilled" &&
        promise.value.items.find(
          (e) => e.student_id === studentId && e.id === session.id,
        ) != null
      );
    },
  );

  @action
  updateProfile = async (dto: IUpdateCustomerDTO) => {
    const customer = await api.profile.update(dto);
    const students = this.customer.students;
    const customerAsParticipant = students.find(
      (s) => s.type === "CUSTOMER_AS_PARTICIPANT",
    );
    if (customerAsParticipant) {
      customerAsParticipant.full_name = customer.full_name;
      customerAsParticipant.first_name = customer.first_name;
      customerAsParticipant.last_name = customer.last_name;
    }
    const updatedCustomer = { ...customer, students: this.customer.students };
    runInAction(() => {
      this._customer = updatedCustomer;
    });
  };

  @action
  createParticipant = async (dto: ICreateStudentDTO) => {
    const student = await api.students.create(dto);
    const updatedCustomer = {
      ...this.customer,
      students: [...this.customer.students, student],
    };
    runInAction(() => {
      this._customer = updatedCustomer;
    });
    return student;
  };

  @action
  leaveWaitlist = async (
    programId: string,
    studentId: number,
    scheduleSetId: string,
  ) => {
    await api.waitlists.removeStudents(scheduleSetId, {
      program_id: programId,
      student_ids: [studentId],
    });
    this.loadWaitlistRecords();
  };

  @action createCustomerAsParticipant = async (
    dto: ICreateCustomerAsParticipantDTO,
  ) => {
    await api.students.createCustomerAsParticipant(dto);
    await this.refreshProfile();
  };

  @action
  updateParticipant = async (id: number, dto: IUpdateStudentDTO) => {
    const student = await api.students.update(id, dto);
    const students = [...this.customer.students];
    const index = students.findIndex((val) => val.id === id);
    students.splice(index, 1, student);

    const updatedCustomer = { ...this.customer, students: [...students] };

    runInAction(() => {
      this._customer = updatedCustomer;
    });
  };

  @action
  removeParticipant = async (id: number) => {
    try {
      await api.students.remove(id);
      const students = [...this.customer.students];
      const index = students.findIndex((val) => val.id === id);
      students.splice(index, 1);
      const updatedCustomer = { ...this.customer, students };

      runInAction(() => {
        this._customer = updatedCustomer;
      });
    } catch (e) {
      Sentry.captureException(e);
      notificator.error(e);
    }
  };

  @action
  loadCredits = async () => {
    if (!this.rootStore.authStore.loggedIn) return;
    const credits = await api.profile.credits();
    runInAction(() => {
      this.credits = credits;
    });
  };

  @action.bound
  async loadSmartForms() {
    const smartForms = await api.questionSets.getAll();
    runInAction(() => (this.smartForms = smartForms));
  }

  @action.bound
  async loadPromocodes() {
    const promocodes = await api.promocodes.getAll();
    runInAction(() => (this.promocodes = promocodes));
  }

  @action.bound
  async loadWaitlistRecords(): Promise<void> {
    const promise = api.waitlists.get();
    this.waitlistRecordsPromise = fromPromise(promise);
    await promise;
  }

  @action
  loadApplications = async () => {
    this.applications = await api.applications.getApplications();
  };

  @computed
  get waitlistRecords(): IWaitlistRecordDTO[] {
    return (
      this.waitlistRecordsPromise?.case({
        fulfilled: (v) => v,
      }) ?? []
    );
  }

  isWaitlisted = computedFn((studentId: number, program: TAvailableProgram) => {
    const promise = this.waitlistRecordsPromise;
    return (
      promise != null &&
      promise.state === "fulfilled" &&
      promise.value.find(
        (wr) => wr.student_id === studentId && wr.program_id === program.id,
      ) != null
    );
  });
  @observable
  private waitlistRecordsPromise?: IPromiseBasedObservable<
    IWaitlistRecordDTO[]
  >;

  @observable
  applications: ApplicationDTO[] = [];

  @action
  init = async () => {
    await Promise.all([
      this.loadCredits(),
      this.loadSmartForms(),
      this.loadPromocodes(),
      this.loadWaitlistRecords(),
      this.loadApplications(),
    ]);

    await this.refreshProfile();
  };

  @action
  refreshProfile = async () => {
    const customer = await api.profile.get();
    runInAction(() => {
      this._customer = customer;
    });
  };

  withdrawApplication = async (applicationId: string) => {
    await api.applications.withdrawApplication(applicationId);
    await this.loadApplications();
  };

  archiveApplication = async (applicationId: string) => {
    await api.applications.archiveApplication(applicationId);
    await this.loadApplications();
  };

  submitApplications = async (
    applicationIds: string[],
    ignore_requires_payment_information: boolean,
  ) => {
    await api.applications.submitApplications({
      application_ids: applicationIds,
      ignore_requires_payment_information,
    });
    await this.loadApplications();
  };
}
